<template>
  <div class="xl:mb-32">
      <section class=" pt-8 xl:h-screen  bg-cover bg-no-repeat min-h-2/4 object-cover bg-opacity-75 background_image relative">
        <!-- <div class=" text-white absolute bottom-0 mb-6 w-full">
          <div v-if="Status == true" class="container  mx-auto px-6 xl:px-12 text-center">
            <h1 class="text-3xl sm:text-4xl lg:text-8xl xl:text-9xl fontBold ls">
                {{ $t("Training.title") }}
            </h1>
            <p class="text-1xl">
                 {{ $t("Training.text") }}
            </p>
          </div>
        </div> -->
      </section>
    <section class="mt-16 container mx-auto px-6 xl:px-12">
        <h2 class="text-5xl sm:text-6xl lg:text-8xl xl:text-9xl fontBold ls ">
                    {{ $t("Training.title") }}

         </h2>
        <p class="text-3xl w-4/5	">
           {{ $t("Training.text") }}

         </p>
      </section>
      <section v-if="Status == true" class="mt-32 container mx-auto px-6 xl:px-12 mb-8">
          <p class="text-4xl">
               {{ $t("Training.Form") }}
          </p>

          <div class="mt-12">
            <label for="name" class="text-xl text-greey font-light">
               {{ $t("Training.Name") }} *
            </label>
            <input type="text" v-model="Name" name="name" id="name" class="block rounded-md py-1 px-2 w-64 border border-gray mt-2 focus:outline-none hover:shadow">
          </div>

          <div class="mt-8">
            <label for="email"  class="text-xl text-greey font-light">
               {{ $t("Training.Email") }} *

            </label>
            <input type="email" v-model="Email" name="email" id="email" class="block rounded-md py-1 px-2 w-64 border border-gray mt-2 focus:outline-none hover:shadow">
          </div>

          <div class="mt-8">
            <label for="phone_number" class="text-xl text-greey font-light">
               {{ $t("Training.PhoneNumber") }} *
            </label>
            <input type="tel" v-model="PhoneNumber" name="phone_number" id="phone_number" class="block rounded-md py-1 px-2 w-64 border border-gray mt-2 focus:outline-none hover:shadow">
          </div>

          <div class="mt-8">
            <label for="work" class="text-xl text-greey font-light">
               {{ $t("Training.Interested") }} *
            </label>

            <div class="ml-6 mt-4 space-y-4">
                <div class="flex items-center">
                  <input v-model="Interested" id="push-branding-strategies" name="push-notifications" value="Branding & Strategies" type="radio" class="h-4 w-4 border-black">
                  <label for="push-branding-strategies" class="ml-3 block text-xl ">
                    Branding & Strategies
                  </label>
                </div>
                
                <div class="flex items-center">
                  <input v-model="Interested" id="push-creative-thinking" name="push-notifications" value="Creative Thinking"  type="radio" class="h-4 w-4 border-black">
                  <label for="push-creative-thinking" class="ml-3 block text-xl ">
                    Creative Thinking
                  </label>
                </div>

                <div class="flex items-center">
                  <input v-model="Interested" id="push-illustration" name="push-notifications" value="Illustration" type="radio" class="h-4 w-4 border-black">
                  <label for="push-illustration" class="ml-3 block text-xl ">
                    Illustration
                  </label>
                </div>

                <div class="flex items-center">
                  <input v-model="Interested" id="push-photography" name="push-notifications" value="Photography" type="radio" class="h-4 w-4 border-black">
                  <label for="push-photography" class="ml-3 block text-xl ">
                    Photography
                  </label>
                </div>
            </div>
          </div>
          <div class="mt-8">
            <label for="address"  class="text-xl text-greey font-light">
                             {{ $t("Training.Address") }}  *
            </label>
            <input type="text" v-model="Address" name="address" id="address" class="block rounded-md py-1 px-2 w-64 border border-gray mt-2 focus:outline-none hover:shadow">
          </div>

          <div class="mt-8">
            <label for="interested" class="text-xl text-greey font-light">
               {{ $t("Training.Cv") }}  
            </label>


            <div class="flex text-sm text-gray-600">
              <label for="file-upload" class="relative cursor-pointer bg-black rounded-md font-medium text-white focus:outline-none mt-2 hover:shadowd  px-4 w-64 flex justify-center items-center text-2xl">
                <span class="pt-2">Upload</span>
               <input id="file-upload" name="file-upload" type="file" class="sr-only" @change="selectedFile($event)"/>
              </label>
              <span class="pt-4 text-2xl">{{namefile}}</span>
            </div>
          </div>

          <div class="mt-8">
            <label for="work" class="text-xl text-greey font-light">
               {{ $t("Training.working") }}  
            </label>

            <div class="ml-6 mt-4 space-y-4">
                <div class="flex items-center">
                  <input id="push-yes" v-model="LookingToWorkWithUS" value="Yes" name="push-notifications" type="radio" class="h-4 w-4 border-black">
                  <label for="push-yes" class="ml-3 block text-xl ">
                           {{ $t("Training.yse") }}  

                  </label>
                </div>
                
                <div class="flex items-center">
                  <input id="push-no"  v-model="LookingToWorkWithUS" value="No"  name="push-notifications" type="radio" class="h-4 w-4 border-black">
                  <label for="push-no"  class="ml-3 block text-xl ">
                            {{ $t("Training.no") }}  

                  </label>
                </div>
            </div>
          </div>
          <div class="mt-8">
            <label for="about_you" class="text-xl text-greey font-light">
               {{ $t("Training.Tellusmoreabout") }}  
            </label>
            <textarea v-model="AboutUser"  name="about_you" id="about_you" cols="50" rows="4" class="block rounded-md py-2 px-2 w-3/4 border border-gray mt-2 focus:outline-none hover:shadowd"></textarea>
          </div>

          <div class="mt-8">
            <label for="hear" class="text-xl text-greey font-light">
               {{ $t("Training.Howdidyouhearaboutus") }}  
            </label>

            <select v-model="HowDidYouHearAboutUs" id="hear" name="hear" autocomplete="hear" class="block rounded-md py-2 px-2 w-64 border border-gray mt-2 focus:outline-none hover:shadowd">
              <option value="Social Media">{{$t("Job.How-did-you-get-to-know.1")}}</option>
              <option value="Search engines">{{$t("Job.How-did-you-get-to-know.2")}}</option>
              <option value="Word of mouth">{{$t("Job.How-did-you-get-to-know.3")}} </option>
              <option value="Other">{{$t("Job.How-did-you-get-to-know.4")}}</option>
            </select>
          </div>

          <div class="mt-8">
            <label for="notes" class="text-xl text-greey font-light">
               {{ $t("Training.Additionalnotes") }}  
            </label>
            <input v-model="AdditionalNotes" type="text" name="notes" id="notes" class="block rounded-md py-1 px-2 w-64 border border-gray mt-2 focus:outline-none hover:shadow">
          </div>

           <div class="mt-8">
            <button @click="submit()" class="bg-black rounded-md font-medium text-white focus:outline-none mt-2 hover:shadowd  px-4 pt-2 pb-2 flex justify-center items-center text-2xl">
                              {{ $t("Training.button") }}  

            </button>
          </div>
      </section>
      <h1 v-else class=" m-6 text-5xl sm:text-6xl lg:text-lg xl:text-xl font-bold text-center">
        {{ $t("Training.Off") }}
      </h1>
  </div>
</template>
<script>
export default {
  data() {
    return {
    Name: "",
    Email: "",
    PhoneNumber: "",
    Interested: "",
    Address: "",
    Portfolio:"",
    LookingToWorkWithUS: '',
    AboutUser: "",
    HowDidYouHearAboutUs: "",
    AdditionalNotes: '',
    addSuccessed:'',
    addErorr:'',
    Status:true,
    namefile:''
    };
  },
  created(){
   this.Check()
  },
  methods: {
    submit() {
console.log('Portfolio',this.Portfolio)
      var form = {
        Name: this.Name,
        Email: this.Email,
        PhoneNumber: this.PhoneNumber,
        Interested: this.Interested,
        Address: this.Address,
        Portfolio:this.Portfolio,
        LookingToWorkWithUS: this.LookingToWorkWithUS,
        AboutUser: this.AboutUser,
        HowDidYouHearAboutUs: this.HowDidYouHearAboutUs,
        AdditionalNotes: this.AdditionalNotes,
       };
       console.log('Portfolio2',form.Portfolio)

        this.$http.trainingService
          .AddTraining(form)
          .then((res) => {
            this.addSuccessed = res.data;
          })
          .catch((err) => {
            this.addErorr = err.response.data;
          });
    },
       Check() {
         let id =1;
        this.$http.trainingService
          .Check(id)
          .then((res) => {
            this.Status = res.data.Check;
          })
          .catch((err) => {
            this.addErorr = err.response.data;
          });
    },
     selectedFile(event){
    let data = new FormData();
    let file = event.target.files[0];
    data.append('name', 'my-file')
    data.append('file', file)
    this.namefile=file.name
    this.Portfolio=file
 
  }
  },
};
</script>

<style >
.background_image{
    background-image: url('../assets/images/Asset 21@300x.jpg')
  }
</style>